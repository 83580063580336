<script lang="ts" setup>
const currentStore = useCurrentStore()
const isMobile = useIsMobile()
</script>

<template>
  <ClientOnly>
    <div class="mt-[58px] w-full overflow-hidden rounded-b-lg sm:mt-[76px]">
      <img
        alt=""
        class="h-full w-full rounded object-cover sm:mx-auto sm:max-h-[470px] sm:max-w-[1280px] sm:rounded-bl-2xl sm:rounded-br-2xl"
        :src="
          optimizedImage(
            currentStore.backgroundImageUri || '/images/banner-image.jpg',
            isMobile ? 'medium' : 'large',
          )
        "
      />
    </div>
  </ClientOnly>
</template>
